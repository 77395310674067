module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.movearoo.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-5LPX5W","brandTokens":["GMO","TND","ADT","VIV","ATT","DTV","VZN","BSP","WDS","CTL","QF"],"mapiBrandToken":"GMO","siteName":"movearoo","alternateName":"movearoo.com","siteURL":"https://www.movearoo.com","defaultTitleTemplate":"","defaultPhone":"8555560821","phoneSymbol":"-","defaultPromoCode":"56547","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046069","vwoProjectID":"894940","datasetManagerAPIKey":"aa533532ff32499ba296ef064353e4bf"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"movearoo","short_name":"movearoo","start_url":"/","background_color":"#C75300","theme_color":"#C75300","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"10cb69bc4a4373739109c481a95af076"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
